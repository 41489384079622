@import '~bootstrap';
@import '~font-awesome';

body {
    background-color: #ECECEC;
}

.displayfield {
    color: blue;
    cursor: pointer
}

.suspended {
    color: red;
}

#languages .current a
{
    color: #8064c8 !important;
    font-weight: bold;
}

.loading {
/*    display:    none;
    position:   fixed;
    z-index:    1000;
    top:        0;
    left:       0;
    height:     100%;
    width:      100%;*/
    background: rgba( 255, 255, 255, .8 )
                url('/images/loading.gif')
                50% 50%
                no-repeat;
}

.my-container {
    background: rgb(234,244,249);
}
.my-filter {
    background: #2980b9; /*rgb(150,170,200);*/
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: rgb(234,244,249);
}

/*        iframe {
     height: 290px;
 }

 @media (max-width: 675px) {
     iframe {
         height:200px;
     }*/
